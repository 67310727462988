<template>
  <div>
    <div class="intro-x flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">鏂伴椈鍒楄〃</h2>
    </div>
    <div class="message-body intro-x">
      <div class="message-box">
        <div
          v-for="(item, index) in messageList"
          :key="index"
          class="messageDiv"
          @click="getDetails(item)"
        >
  
          <div class="mssageHeader">
            <div class="headerItem">
              <div class="head-">
                <img :src="item.userHead" alt="" />
              </div>
              <div class="head-font">
                <div style="font-size: 14px; colo: #2d3748">
                  {{ item.userName }}
                </div>
                <div>
                  <span
                    class="timeLeft"
                    style="font-size: 12px; color: #718096"
                    >{{ item.timeStr }}</span
                  >
                </div>
              </div>
              <div
                ref="nextTick"
                class="headFloat-right"
                @click.stop="itemPop(index)"
              >
                <div class="el-icon-more"></div>
              </div>
            </div>
            <div class="headerContent">
              <img :src="item.image" alt="" />
              <div class="contentDiv">
                <h1 style="font-size: 16px; margin: 0px 0 10px">
                  {{ item.title }}
                </h1>
                <h2 class="contentHtml" style="font-size: 14px" v-html="item.content">
                  <!-- {{ item.content }} -->
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="pagination-box intro-x">
      <el-pagination
        hide-on-single-page
        :page-size="20"
        :pager-count="11"
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import Cookies from "js-cookie";
import request from "../utils/request";

export default {
  data() {
    return {
      messageList: [],
    };
  },
  activated() {
    if (Cookies.get("userInfo")) {
      this.userInfo = JSON.parse(Cookies.get("userInfo"));
    } else {
      this.userInfo = null;
    }
  },

  mounted() {
    this.getInit();
  },
  methods: {
    itemPop: function (index) {
      for (let i in this.messageData) {
        if (i == index) {
          this.messageData[i].type = !this.messageData[i].type;
        } else {
          this.messageData[i].type = false;
        }
      }
    },
    getDetails: function (data) {
      this.$router.push( { path: "chat/CreateAn" ,query: data} );
    },

    getInit: function () {
      request({
        url: "/api/message/list/1/20",
        method: "GET",
      }).then((res) => {
        this.messageList = res.data.imgList;
      });
    }, 
  },
};
</script>


<style lang="scss" scoped>
.message-body {
  width: calc(100% + 24px);
}

.message-box {
  width: 100%;
  overflow: hidden;
  .messageDiv {
    cursor: pointer;
    min-width: 450px;
    width: 31.7%;
    height: 429px;
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.03);
    opacity: 1;
    border-radius: 22px;
    float: left;
    margin: 24px 1.6% 24px 0;
    box-sizing: border-box;
    position: relative;
    .PopStatus {
      position: absolute;
      width: 152px;
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      top: 47px;
      right: 20px;
      background: #fff;
      font-size: 14px;
      color: #2d3748;
      box-sizing: border-box;
      z-index: 999;
      .div1 {
        padding-left: 16px;
        height: 44px;
        line-height: 44px;
      }
      .div1:hover {
        background: rgba(233, 233, 233, 0.3);
        cursor: pointer;
      }
      .div1:active {
        background: rgba(233, 233, 233, 0.5);
      }
    }
    .mssageHeader {
      width: 100%;
      height: 73px;
      box-sizing: border-box;
      border-bottom: 1px solid #e2e8f0;
      box-sizing: border-box;
      .headerItem {
        padding: 15px 5%;
        overflow: hidden;
        height: 100%;
        box-sizing: border-box;
        .head- {
          float: left;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 12px;
        }
        .head-font {
          float: left;
        }
        .headFloat-right {
          display: none;
          float: right;
          color: #718096;
          font-size: 16px;
          text-align: center;
          width: 40px;
          height: 100%;
          line-height: 43px;
          cursor: pointer;
          div {
            transform: rotate(90deg);
            display: inline-block;
          }
        }
      }
      .headerContent {
        padding: 15px 5%;
        overflow: hidden;
        img {
          width: 100%;
          height: 224px;
        }
        .contentDiv {
          height: 111px;
          width: 100%;
          box-sizing: border-box;
          color: #2d3748;
          padding-top: 20px;
          overflow: hidden;
        }
      }
    }
  }
}


.contentHtml {
  height: 50px;
   width: 100%;
  overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .message-body {
    width: 100% !important;
    .mssageHeader {
      height: auto !important;
    }

    .messageDiv {
      min-width: 100% !important;
      height: auto !important;
      font-size: 1rem !important;
      .contentDiv {
        height: auto !important;
        h1 {
          font-size: 1rem !important;
        }
        h2 {
          font-size: 0.9rem !important;
        }
      }
      .headerContent img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
</style>